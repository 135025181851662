












































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
      partners: [],
      total: 0,
      groups: [],
    });

    const model = reactive<{
      data: any;
    }>({
      data: {
        partnerGroupId: "",
        partner: "",
        scale: 1,
        state: true,
      },
    });

    //  Start fetch single partner data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`partner/assign/${root.$route.params.spid}`)
        .then(({ data: { partner } }) => {
          model.data.partnerGroupId = partner.partnerGroup.id;
          model.data.partner = partner.partner.id;
          model.data.scale = partner.scale === "1.0" ? 1 : partner.scale;
          model.data.state = partner.state;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single partner data

    //  Start fetch partners

    const fetchPartners = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("partner")
        .then(({ data: { partners, total } }) => {
          state.empty = false;
          state.partners = partners.map((partner: any) => ({ ...partner }));
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.partners = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchPartners);

    //  End fetch partners

    //  Start fetch partner groups

    const fetchGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/partner/group`)
        .then(({ data: { partnerGroups } }) => {
          state.empty = false;
          state.groups = partnerGroups;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.groups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchGroups);

    //  End fetch partner groups

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        partnerGroupId: model.data.partnerGroupId,
        state: model.data.state,
        scale: model.data.scale,
      };

      state.loading = true;

      axiosInstance
        .put(
          `/partner/${model.data.partner}/assign/${root.$route.params.spid}`,
          data,
          {
            headers: {
              event: root.$route.params.id,
            },
          }
        )
        .then(() => {
          state.success = true;
          model.data = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Partner już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    const scale = [
      { value: 0.1, name: "10%" },
      { value: 0.2, name: "20%" },
      { value: 0.3, name: "30%" },
      { value: 0.4, name: "40%" },
      { value: 0.5, name: "50%" },
      { value: 0.6, name: "60%" },
      { value: 0.7, name: "70%" },
      { value: 0.8, name: "80%" },
      { value: 0.9, name: "90%" },
      { value: 1, name: "100%" },
    ];

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      scale,
    };
  },
});
